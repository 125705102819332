import * as React from 'react'
import * as ReactDOM from 'react-dom/client'

import { Header } from './components/header'
import { Home } from './containers/home'

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <Header>
      Logo
    </Header>
    <Home />
  </React.StrictMode>
)
