import * as React from 'react'

export interface HomeProps {
  children?: React.ReactNode
}

export const Home: React.FC<HomeProps> = ({ children }) => {
  return (
    <div>
      <h1>Home</h1>
    </div>
  )
}
